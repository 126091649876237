<template>      
    <form @submit.prevent="checkForm" class="mb-4">
        <div class="row">
			<div class="col-12">
				<div class="form-group">
					<label for="ispayment_date">{{ $t('payment.payment_date') }} *</label>
					<e-datepicker v-model="ispayment_local.ispayment_date"></e-datepicker>
				</div>
			</div>
			<template v-if="payment.method.paymentmethod_code == 'CH'">
				<div class="col-12 col-sm-6">
					<div class="form-group">
						<label for="ispayment_bankcheck">{{ $t('payment.payment_bankcheck') }}</label>
						<input id="ispayment_bankcheck" class="form-control" v-model="ispayment_local.ispayment_bankcheck" :placeholder="this.getTrad('payment.payment_bankcheck_placeholder')">
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div class="form-group">
						<label for="ispayment_bank">{{ $t('payment.payment_bank') }}</label>
						<e-select
							id="ispayment_bank"
							v-model="ispayment_local.bank"
							:options="payment_banks"
							track-by="paymentbank_id"
							label="paymentbank_label"
							:allow-empty="false"
						/>
					</div>
				</div>
			</template>
			<div class="col-12">
				<div class="form-group">
					<label for="ispayment_comment">{{ $t('payment.payment_comment') }}</label>
					<textarea id="ispayment_comment" class="form-control" v-model="payment.ispayment_comment" :placeholder="this.getTrad('payment.payment_comment_placeholder')" rows="2"></textarea>
				</div>
			</div>
		</div>
    </form>
</template>

<script type="text/javascript">
	import Payment from "@/mixins/Payment.js"

	export default {
		name: "InvoiceSupplierEditPayment",
		mixins: [Payment],
		props: {
			payment: {
				type: Object,
				default: null
			},
		},
		data () {
			return {
				ispayment_local: {},
				processing: false,
				payment_banks: this.getConfig('payment_bank'),
				errors: [],
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
				this.ispayment_local = this.payment

				this.$emit('update:ready', true)
			},
			async checkForm() {

			},

			async editPayment() {
				this.errors = []

				if(!this.processing) {
					this.processing = true
				
					this.$emit('update:processing', true)


					if(!Date.parse(this.ispayment_local.ispayment_date)) {
						this.errors.push("Date")
					}

					if(this.errors.length > 0) {
						this.processing = false
						this.$emit('update:processing', false)
						return false
					}

					const params = {
						ispayment_date: this.ispayment_local.ispayment_date,
						ispayment_bankcheck: this.ispayment_local.ispayment_bankcheck,
						ispayment_bank: (this.ispayment_local.bank) ? this.ispayment_local.bank.paymentbank_id : null,
						ispayment_comment: this.ispayment_local.ispayment_comment,
					}

					const result = await this.saveEditPaymentSupplier(this.ispayment_local.ispayment_id, params)

					if(result) {
						this.$emit('update:processing', false)
						this.processing = false
						this.successToast()
						return true
					}
					else {
						this.processing = false
						this.$emit('update:processing', false)
						return false
					}
				}
			},
		}
	}
</script>